import { useFormContext } from 'react-hook-form';
import { Box, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ImagePreview from '../ImagePreview';

interface ImagePreviewFormControlProps {
    name: string;
}

export const ImagePreviewFormControl = ({ name }: ImagePreviewFormControlProps) => {
    const { watch, setValue } = useFormContext();
    const file = watch(name);
    const fileUrl = file ? URL.createObjectURL(file) : null;

    const clearFile = () => {
        setValue(name, null);
    };

    return (
        <Box>
            {fileUrl && (
                <Box position="relative" display="inline-block">
                     {(fileUrl) && (
                        <ImagePreview
                            url={fileUrl}
                            altText="Медицинская справка"
                        />
                    )}
                    
                    <IconButton
                        onClick={clearFile}
                        aria-label="Remove image"
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            backgroundColor: 'white',
                        }}
                    >
                        <ClearIcon />
                    </IconButton>
                </Box>
            )}
        </Box>
    );
};