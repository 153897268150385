import React, { useEffect } from 'react';
import { Alert } from '@mui/material';

interface ErrorAlertProps {
    message: string;
    onClose: () => void;
    autoCloseDuration?: number;
}

const ErrorAlert: React.FC<ErrorAlertProps> = ({ message, onClose, autoCloseDuration = 10000 }) => {
    useEffect(() => {
        if (autoCloseDuration) {
            const timer = setTimeout(onClose, autoCloseDuration);
            return () => clearTimeout(timer);
        }
    }, [onClose, autoCloseDuration]);

    return (
        <Alert
            severity="error"
            onClose={onClose}
            sx={{ mb: 2 }}
        >
            {message}
        </Alert>
    );
};

export default ErrorAlert;
