import api from './api';
import {DriverFilter} from "../models/drivers/driverFilter";
import {DriverShortDto} from "../models/drivers/driverShortDto";
import {AppResponse} from "../models/common/app-response";
import { PaginatedResponse } from '../models/common/paginated-response';
import { DriverDto } from '../models/drivers/driverDto';
import { FormDataUtils } from '../helpers/formDataUtils';
import { EditDriverDto } from '../models/drivers/editDriverDto';
import { ImportDriversDto } from '../models/drivers/ImportDriversDto';

class DriversService {

  baseUrl: string = "/api/v1/drivers";

    getByFilter(filter? : DriverFilter) {
        return api.get<AppResponse<PaginatedResponse<DriverShortDto>>>('/api/v1/drivers',{ params: filter });
    }

    getById(id: number) {
        return api.get<AppResponse<DriverDto>>(`/api/v1/drivers/${id}`);
    }

    addDriver(data : EditDriverDto) {
        const formData = FormDataUtils.toFormData(data);
        return api.post<AppResponse<number>>('/api/v1/drivers', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
    }

    updateDriver(data: EditDriverDto) {
        const formData = FormDataUtils.toFormData(data);
        return api.put<AppResponse<number>>(`/api/v1/drivers/${data.id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
    }

    dismiss(id: number) {
        return api.patch<AppResponse<number>>(`/api/v1/drivers/${id}`);
    }

    importDrivers(data : ImportDriversDto) {
      const formData = FormDataUtils.toFormData(data);
      return api.post<AppResponse<number[]>>(this.baseUrl + `/import`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  }
}

export const driversService = new DriversService();