import { LookupDto } from "../common/lookup.dto";
import { HealthCertificateDto } from "./healthCertificate.dto";

/**
 * Interface representing the health certificate add form.
 */
export interface EditHealthCertificateFormDto{
    id?: number | null;
    driverId?: number | null;
    type: LookupDto | null;
    issued: Date | null;
    certificateFile: File | null;
    certificateUrl: string | null;
}

export const mapToEditHealthCertificateFormDto = (formData: HealthCertificateDto): EditHealthCertificateFormDto => {
    return {
        id: formData.id,
        driverId: formData.driverId,
        type: formData.type,
        issued: formData.issued,
        certificateFile: formData.photoFile,
        certificateUrl: formData.photoUrl
    };
}