import React, { useState } from 'react';
import { Box, Typography, Stack, IconButton, Grid } from '@mui/material';
import AddCertificateDialog from '../AddCertificateDialog';
import { EditHealthCertificateFormDto } from '../../../models/healthCertificates/editHealthCertificateFormDto';
import {  useFormContext } from 'react-hook-form';
import { TextFieldFormControl } from '../../common/form/TextFieldFormControl';
import { EditDriverFormDto } from '../../../models/drivers/editDriverFormDto';
import { HealthCertificateCardFormControl } from '../../common/HealthCertificateCardFormControl';
import TmPlus from '../../../icons/TmPlus';

interface EditMedicalDataProps {
    setError: (message: string) => void;
}

export const EditMedicalDataTab: React.FC<EditMedicalDataProps> = () => {
    const { getValues, setValue, watch } = useFormContext<EditDriverFormDto>();
    const [addCertificateDialogOpen, setAddCertificateDialogOpen] = useState(false);

    const handleAddCertificate = (certificateData: EditHealthCertificateFormDto) => {
        const updatedCertificates = [...getValues('healthCertificates'), certificateData];
        setValue('healthCertificates', updatedCertificates);
    };

    const handleRemoveCertificate = (indexToRemove: number) => {
        const existCertificates = getValues('healthCertificates');
        const updatedCertificates = existCertificates.filter((_: any, i: number) => i !== indexToRemove);
        setValue('healthCertificates', [...updatedCertificates]);
    };

    const healthCertificates = watch('healthCertificates').map(hc => ({
        ...hc,
        issued: hc.issued ? new Date(hc.issued) : null
    }));

    return (
        <Box>
            <Typography variant='h6' fontWeight="bold" mb="10px">Справки</Typography>
            <Stack direction="row" justifyContent="flex-start" mb={2.5}>
                <IconButton className="list-item" sx={{ ml: "20px", pr: "15px"}} onClick={(e) => setAddCertificateDialogOpen(true)}>
                    <TmPlus fill="none" stroke="white"/>
                    <Typography fontWeight="bold">
                        &nbsp;Добавить справку
                    </Typography>
                </IconButton>
                
            </Stack>

            {healthCertificates && healthCertificates.length > 0 && <Grid container spacing={4}>
                {healthCertificates.map((hc, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                        <HealthCertificateCardFormControl healthCertificate={hc} onImageRemove={() => handleRemoveCertificate(index)} />
                    </Grid>
                ))}
            </Grid>}

            <Typography variant='h6' fontWeight="bold" mb="10px">Норма медицинских показателей</Typography>
            <Stack spacing={2} direction="column">
                <Typography fontWeight="bold" mb={1}>Артериальное давление</Typography>
                <Box display="flex">
                    <TextFieldFormControl
                        name="defaultIndicators.upperPressureMin"
                        label="Мин. арт. давление"
                        type="number"
                        required
                        style={{ marginRight: 10 }}
                    />
                    <TextFieldFormControl
                        name="defaultIndicators.upperPressureMax"
                        label="Макс. арт. давление"
                        type="number"
                        required
                    />
                </Box>
                <Typography fontWeight="bold" mb={1}>Венозное давление</Typography>
                <Box display="flex">
                    <TextFieldFormControl
                        name="defaultIndicators.lowerPressureMin"
                        label="Мин. вен. давление"
                        type="number"
                        required
                        style={{ marginRight: 10 }}
                    />
                    <TextFieldFormControl
                        name="defaultIndicators.lowerPressureMax"
                        label="Макс. вен. давление"
                        type="number"
                        required
                        style={{ marginRight: 10 }}
                    />
                </Box>
                <Typography fontWeight="bold" mb={1}>Пульс</Typography>
                <Box display="flex">
                    <TextFieldFormControl
                        name="defaultIndicators.pulseMin"
                        label="Мин. пульс"
                        type="number"
                        required
                        style={{ marginRight: 10 }}
                    />
                    <TextFieldFormControl
                        name="defaultIndicators.pulseMax"
                        label="Макс. пульс"
                        type="number"
                        required
                    />
                </Box>
                <Typography fontWeight="bold" mb={1}>Температура</Typography>
                <TextFieldFormControl
                    name="defaultIndicators.temperatureMax"
                    label="Макс. температура"
                    type="number"
                    required
                />
            </Stack>
            <AddCertificateDialog
                driverId={getValues("id")}
                open={addCertificateDialogOpen}
                onClose={() => setAddCertificateDialogOpen(false)}
                onAddCertificate={handleAddCertificate}
            />
        </Box>
    );
};