import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Typography, IconButton, Box, Avatar, CardContent, Card, Chip } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Spinner from '../../common/Spinner';
import { ROUTES } from '../../../constants/routes';
import { terminalsService } from '../../../services/terminalsService';
import { TerminalDetailsDto } from '../../../models/terminals/terminalDetailsDto';
import { EditTerminalModal } from '../edit/EditTerminalModal';
import AcceptLabel from '../../common/labels/AcceptLabel';
import DenyLabel from '../../common/labels/DenyLabel';
import { DateUtils } from '../../../helpers/dateUtils';
import TmPencil from '../../../icons/TmPencil';
import { CategoryType } from '../../../models/enums/categoryType';
import { PermissionType } from '../../../models/enums/permissionType';
import { ShowForPermission } from '../../common/ShowForPermission';

export const TerminalDetails: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [terminal, setTerminal] = useState<TerminalDetailsDto>();
  const [isEditPopupOpen, setEditPopupOpen] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const terminalId = Number(params.id);

    terminalsService.getById(terminalId)
      .then(r => setTerminal(r.data.data))
      .catch(e => {
        console.error(e);
        if (e.response.status === 404) {
          navigate(ROUTES.auth.notFound);
        }
      })
      .finally(() => setLoading(false));
  }, [params.id]);

  const refreshData = () => {
    if (!params.id) return;

    const terminalId = Number(params.id);
    setLoading(true);

    terminalsService.getById(terminalId)
      .then(r => setTerminal(r.data.data))
      .catch(e => console.error(e))
      .finally(() => setLoading(false));
  };

  function handleEditPopupToggle(): void {
    setEditPopupOpen(!isEditPopupOpen);
  }

  return (
    <Box>
      {loading
        ? <Spinner />
        : (<Box>

          <Grid container alignItems="center" justifyContent="space-between">

            <Grid item xs={2}>
              <IconButton onClick={() => navigate(-1)} sx={{ my: 2 }}>
                <Avatar sx={{ backgroundColor: "white", border: 1, borderColor: "#9588e8" }}>
                  <ChevronLeftIcon sx={{ color: '#9588e8' }} />
                </Avatar>
              </IconButton>
            </Grid>

            <Grid item xs={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant="h6" align="center">Данные терминала</Typography>
              </Grid>

              <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ShowForPermission catalog={CategoryType.Terminals} permission={PermissionType.Edit}>
                  <IconButton className="list-item" sx={{px: "20px"}} onClick={handleEditPopupToggle}>
                    <TmPencil fill="none" stroke="white" />&nbsp;&nbsp;
                    <Typography fontWeight="bold">Редактировать</Typography>
                  </IconButton>
                </ShowForPermission>
              </Grid>

          </Grid>

          <Grid container spacing={2} mt={0.5}>

            <Grid item xs={12} container spacing={2}>

              <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box className="gbr" px={2} pb={2} sx={{ flex: 1 }}>
                  <Typography pt={2} pb={1} variant="h5" fontWeight="bold">
                    Общая информация
                  </Typography>
                  <Box>
                    <Typography className="ltb" variant="body1">ID&nbsp;</Typography>
                    <Typography className="rtb">{terminal?.id}</Typography>
                  </Box>
                  <Box>
                    <Typography className="ltb" variant="body1">Серийный номер&nbsp;</Typography>
                    <Typography className="rtb">{terminal?.number}</Typography>
                  </Box>
                  <Box>
                    <Typography className="ltb" variant="body1">HardwareId&nbsp;</Typography>
                    <Typography className="rtb">{terminal?.hardwareId}</Typography>
                  </Box>
                  <Box>
                    <Typography className="ltb" variant="body1">Название&nbsp;</Typography>
                    <Typography className="rtb">{terminal?.name}</Typography>
                  </Box>
                  <Box>
                    <Typography className="ltb" variant="body1">Адрес&nbsp;</Typography>
                    <Typography className="rtb">{terminal?.address}</Typography>
                  </Box>
                  <Box>
                    <Typography className="ltb" variant="body1">Дата создания&nbsp;</Typography>
                    <Typography className="rtb">{terminal?.createdDate ? DateUtils.formatDate(terminal?.createdDate) : ""}</Typography>
                  </Box>
                  <Box>
                    <Typography className="ltb" variant="body1">Статус&nbsp;</Typography>
                    <Typography className="rtb">{terminal?.isActive ? <AcceptLabel>Активен</AcceptLabel> : <DenyLabel>Не активен</DenyLabel>}</Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box className="gbr" pb={2.5} sx={{ flex: 1 }}>
                  <Typography px={2} pt={2} variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                    Клиенты и объекты
                  </Typography>
                  <Typography px={2} variant="body1" sx={{ marginBottom: 1 }} className="grey">Клиенты</Typography>
                  <Grid container spacing={2} paddingLeft={2} paddingBottom={1} textAlign="center">
                    {terminal?.companies
                      ? terminal?.companies.map((company, index) => (
                        <Box key={index} className="gbr" py={1} px={2} mt={1.5} ml={2}>
                          <Typography variant="body2" fontWeight="bold" key={company.id}>{company.name}</Typography>
                        </Box>
                      ))
                      : ''}
                  </Grid>
                  <Typography px={2} variant="body1" sx={{ marginBottom: 1 }} className="grey">Объекты</Typography>
                  <Grid container spacing={2} paddingLeft={2} paddingBottom={1} textAlign="center">
                    {terminal?.departments
                      ? terminal?.departments.map((d, index) => (
                        <Box key={index} className="gbr" py={1} px={2} mt={1.5} ml={2}>
                          <Typography variant="body2" fontWeight="bold" key={d.id}>{d.name}</Typography>
                        </Box>
                      ))
                      : ''}
                  </Grid>
                </Box>
              </Grid>

            </Grid>

            <Grid item xs={12}>
              <Box className="gbr" px={2} pb={2} sx={{ height: '220px' }}>
                <Typography pt={2} pb={1} variant="h5" fontWeight="bold">
                  Описание терминала
                </Typography>
                <Box>
                  <Typography className="rtb">{terminal?.description}</Typography>
                </Box>
              </Box>
            </Grid>

          </Grid>

          <EditTerminalModal onClose={handleEditPopupToggle} onRefresh={refreshData} isAdding={false} terminal={terminal} open={isEditPopupOpen} />
        </Box>)}
    </Box >
  );
};
