import { Avatar, Backdrop, Box, Fade, Grid, IconButton, Modal, Typography } from "@mui/material";
import { DateUtils } from "../../../helpers/dateUtils";
import { Gender, getGenderString } from "../../../models/enums/gender";
import { useEffect, useState } from "react";
import maleAvatar from '../../../assets/images/male-avatar.png';
import femaleAvatar from '../../../assets/images/female-avatar.png';
import { DriverDto } from "../../../models/drivers/driverDto";
import { UserUtils } from "../../../helpers/userUtils";
import { CategoryType } from "../../../models/enums/categoryType";
import { PermissionType } from "../../../models/enums/permissionType";
import { ShowForPermission } from "../../common/ShowForPermission";
import DenyLabel from "../../common/labels/DenyLabel";
import AcceptLabel from "../../common/labels/AcceptLabel";
import TmPencil from "../../../icons/TmPencil";

interface ViewPersonalDataTabProps {
    driver: DriverDto;
    onEditClick: () => void;
}

export const ViewPersonalDataTab: React.FC<ViewPersonalDataTabProps> = ({ driver, onEditClick }) => {
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        setImageUrl(driver.user.photoUrl || (driver.user.gender === Gender.Male ? maleAvatar : femaleAvatar));
    }, [driver]);

    const handleClose = () => {
        setOpen(false);
    };

    const [open, setOpen] = useState(false);
    const [image, setImage] = useState("false");

    const handleImage = (value: any) => {
        setImage(value);
        setOpen(true);
    };

    function renderSectionItem(label: string, content: string) {
        return (
            <div>
                <Typography className="ltb" variant="body1">{label}</Typography>
                &nbsp; <Typography className="rtb">{content}</Typography>
            </div>
        );
    }

    return (
        <Box p={0.5}>
            <Grid container px={3} pt={1} pb={1} mt={1} className="gbr">
                <Grid item md={3} xs={12}>
                    <Typography className="ltb">Табельный №&nbsp;</Typography>
                    <Typography className="rtb">{driver?.employeeNumber}</Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Typography className="ltb">Должность:&nbsp;</Typography>
                    <Typography className="rtb">Водитель</Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Typography className="ltb">Статус:&nbsp;</Typography>
                    <Typography className="rtb">{driver?.isDismissed ? <DenyLabel>Уволен</DenyLabel> : <AcceptLabel>Работает</AcceptLabel>}</Typography>
                </Grid>
                <Grid item md={3} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <ShowForPermission catalog={CategoryType.Drivers} permission={PermissionType.Edit}>
                        <IconButton className="list-item" sx={{px: "20px"}} onClick={onEditClick}>
                            <TmPencil fill="none" stroke="white" />&nbsp;&nbsp;
                            <Typography fontWeight="bold">
                                Редактировать
                            </Typography>
                        </IconButton>
                    </ShowForPermission>
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={0.5}>
                <Grid item xs={12} md={12}>
                    <Box component="div" className="container">
                        <Box className="gbr" px={2} pb={2}>
                            <Typography pt={2} pb={1} variant="h5" fontWeight="bold">
                                Личные данные
                            </Typography>
                            {renderSectionItem("ФИО", UserUtils.getFio(driver.user))}
                            {renderSectionItem("Пол", getGenderString(driver.user.gender))}
                            {renderSectionItem("Год рождения", `${DateUtils.getYear(driver.user.birthDate)} (${DateUtils.ageToStr(DateUtils.getAge(driver.user.birthDate))})`)}
                            <Typography variant="h5" pt={2} pb={1} sx={{ fontWeight: 'bold' }}>
                                Фото водителя
                            </Typography>
                            {(imageUrl)
                            ? (
                                <img
                                    src={imageUrl}
                                    alt='Фото водителя'
                                    onClick={(e) => handleImage(imageUrl)}
                                    style={{ width: '30%', maxWidth: "30%", borderRadius: "25px" }}
                                />
                            )
                            : (<Avatar
                                alt="Фото водителя"
                                src={(driver.user.gender === Gender.Male ? maleAvatar : femaleAvatar)}
                                sx={{ width: 100, height: 100 }}
                            />)}
                        
                        </Box>
                        <Box className="gbr" pb={2.5}>
                            <Typography px={2} pt={2} variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                                Клиент и объекты
                            </Typography>
                            <Typography px={2} variant="body1" sx={{ marginBottom: 1 }} className="grey">Клиент</Typography>
                            <Grid container spacing={2} paddingLeft={2} paddingBottom={1} textAlign="center">
                                <Box className="gbr" py={1} px={2} mt={1.5} ml={2}>
                                    <Typography variant="body2" fontWeight="bold">{driver.company?.name}</Typography>
                                </Box>
                            </Grid>
                            <Typography px={2} variant="body1" sx={{ marginBottom: 1 }} className="grey">Объекты клиента</Typography>
                            <Grid container spacing={2} paddingLeft={2} paddingBottom={5.5} textAlign="center">
                                {driver.departments.map((department, index) => (
                                    <Box key={index} className="gbr" py={1} px={2} mt={1.5} ml={2}>
                                        <Typography variant="body2" fontWeight="bold" key={department.id}>{department.name}</Typography>
                                    </Box>
                                ))}
                            </Grid>
                        
                        </Box>
                        
                    </Box>
                    
                </Grid>
                
                <Grid item xs={12} md={12}>
                    <Box className="gbr" mt={0.5} px={2} pb={2} pt={2}>
                        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                            Документы
                        </Typography>
                        <Grid container spacing={1} mt={1}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1" fontWeight="bold">Водительское удостоверение</Typography>
                                {renderSectionItem("Серия -", driver.licenceSerial ?? "Не указано")}
                                {renderSectionItem("Номер -", driver.licenceNumber ?? "Не указано")}
                                {renderSectionItem("Дата выдачи -", driver.licenceIssued ? DateUtils.formatDate(driver.licenceIssued) : "Не указано")}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1" fontWeight="bold">Паспорт</Typography>
                                {renderSectionItem("Серия -", driver.passportSerial ?? "Не указано")}
                                {renderSectionItem("Номер -", driver.passportNumber ?? "Не указано")}
                                <Typography variant="body1" fontWeight="bold" pt={1}>Снилс</Typography>
                                {renderSectionItem("№ Снилс -", driver.snils ?? "Не указано")}
                            </Grid>
                        </Grid>
                    </Box>           
                </Grid>
            </Grid>
            
            <Modal
                className='modal-img'
                open={open}
                onClose={handleClose}
                closeAfterTransition
            >
                <img
                    src={image}
                    alt='Health Certificate'
                    style={{ maxHeight: "90%", maxWidth: "90%" }}
                />
            </Modal>
        </Box>
    );
};