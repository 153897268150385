import React from 'react';
import { permissionService } from '../../services/permissionService';
import { RoleType } from '../../models/enums/roleType';

type ShowForPermissionProps = {
    role: RoleType;
    children: React.ReactNode;
};

export const ShowForRole: React.FC<ShowForPermissionProps> = ({ role, children }) => {
    const isAllowed = permissionService.hasRole(role);

    return isAllowed ? <>{children}</> : null;
};
