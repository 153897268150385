import api from './api';
import {AppResponse} from "../models/common/app-response";
import { LookupDto } from '../models/common/lookup.dto';
import { TerminalShortInfoDto } from '../models/terminals/terminalShortInfoDto';
import { PaginatedResponse } from '../models/common/paginated-response';
import { TerminalFilter } from '../models/terminals/terminalFilter';
import { EditTerminalDto } from '../models/terminals/editTerminalDto';
import { TerminalDetailsDto } from '../models/terminals/terminalDetailsDto';
import { AddTerminalDto } from '../models/terminals/addTerminalDto';

class TerminalsService {

    baseUrl: string = "/api/v1/terminals";

    getByFilter = (filter? : TerminalFilter) => {
        return api.get<AppResponse<PaginatedResponse<TerminalShortInfoDto>>>(this.baseUrl,{ params: filter });
    }

    search = (name: string) => {
        return api.get<AppResponse<LookupDto[]>>(`${this.baseUrl}/search?name=${name}`);
    }

    getById = (id: number) => {
        return api.get<AppResponse<TerminalDetailsDto>>(`${this.baseUrl}/${id}`);
    }

    add = (data : AddTerminalDto) => {
        return api.post<AppResponse<number>>(this.baseUrl, data);
    }

    edit = (data : EditTerminalDto) => {
        return api.put<AppResponse<number>>(`${this.baseUrl}/${data.id}`, data);
    }
}

export const terminalsService = new TerminalsService();