import { Typography, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import ClearIcon from '@mui/icons-material/Clear';
import { EditHealthCertificateFormDto } from "../../models/healthCertificates/editHealthCertificateFormDto";
import { DateUtils } from "../../helpers/dateUtils";
import ImagePreview from "./ImagePreview";

interface HealthCertificateCardFormControlProps {
    healthCertificate: EditHealthCertificateFormDto;
    onImageRemove: () => void;
}

export const HealthCertificateCardFormControl = ({ healthCertificate, onImageRemove }: HealthCertificateCardFormControlProps) => {
    const fileUrl = healthCertificate.certificateFile ? URL.createObjectURL(healthCertificate.certificateFile) : healthCertificate.certificateUrl;

    return (
        <Box sx={{ padding: 1, position: 'relative' }}>

            {(fileUrl) && (
                <ImagePreview
                    url={fileUrl}
                    altText="Медицинская справка"
                />
            )}

            <IconButton
                onClick={onImageRemove}
                aria-label="Remove healthCertificate"
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'white',
                }}
            >
                <ClearIcon />
            </IconButton>
            
            <Typography variant="body2" sx={{ mt: 1 }} fontWeight="bold">{healthCertificate.type?.name}</Typography>
            <Typography component="span" variant="body2">Срок действия до:</Typography>&nbsp;<Typography component="span" variant="body2" fontWeight="bold">{DateUtils.formatDate(healthCertificate.issued)}</Typography>
        </Box>
    );
};