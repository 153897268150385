import { FormHelperText, Typography, Box, IconButton } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';

interface FileUploadAreaFormControlProps {
    name: string;
    accept: string;
    acceptableMimeTypes: string[];
    required: boolean;
    onError?: (message: string) => void,
    uploadDescription?: string;
}

export const FileUploadAreaFormControl = ({ name, accept, acceptableMimeTypes, required, onError, uploadDescription = 'Загрузить' }: FileUploadAreaFormControlProps) => {
    const { control, formState: { errors } } = useFormContext();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const handleFileChange = useCallback(
        (file: File | null, onChange: (file: File | null) => void) => {
            setSelectedFile(file);
            onChange(file);
        },
        []
    );

    const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    }, []);

    const onDrop = useCallback((event: React.DragEvent<HTMLDivElement>, onChange: (file: File | null) => void) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files && files.length > 0) {
            const file = files[0];
            if (acceptableMimeTypes.includes(file.type)) {
                handleFileChange(file, onChange);
            } else {
                if (onError) {
                    onError('Неверный тип файла');
                }
            }
        }
    }, [acceptableMimeTypes, handleFileChange, onError]);

    const handleDeleteFile = useCallback((onChange: (file: File | null) => void) => {
        setSelectedFile(null);
        onChange(null);
    }, []);

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={null}
            rules={{ required: required && 'Поле обязательно для заполнения' }}
            render={({ field: { onChange, ref } }) => (
                <>
                    <input
                        id={`${name}-file-input`}
                        type="file"
                        accept={accept}
                        onChange={(event) => handleFileChange(event.target.files ? event.target.files[0] : null, onChange)}
                        ref={ref}
                        style={{ display: 'none' }}
                        required={required}
                    />
                    <label htmlFor={`${name}-file-input`} style={{ display: 'block', height: '100%' }}>
                        <Box
                            sx={{
                                border: '2px dashed grey',
                                borderRadius: '20px',
                                padding: 2,
                                textAlign: 'center',
                                cursor: 'pointer',
                                bgcolor: 'background.paper',
                                height: '200px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                transition: 'border-color 0.3s',
                                '&:hover': {
                                    borderColor: '#9588e8'
                                }
                            }}
                            onDragOver={onDragOver}
                            onDrop={(event) => onDrop(event, onChange)}
                        >
                            <CloudUploadIcon sx={{ color: '#9588e8', fontSize: 80 }} />
                            <Typography component="div" sx={{ mt: 1 }}>
                                {uploadDescription}
                            </Typography>
                            {errors[name] && (
                                <FormHelperText error>{errors[name]?.message as string}</FormHelperText>
                            )}
                        </Box>
                    </label>
                    {selectedFile && (
                        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body2">{selectedFile.name}</Typography>
                            <IconButton onClick={() => handleDeleteFile(onChange)} sx={{ ml: 1 }}>
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    )}
                </>
            )}
        />
    );
};
