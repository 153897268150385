import api from './api';
import { AppResponse } from "../models/common/app-response";
import { PaginatedResponse } from '../models/common/paginated-response';
import { DispatcherlFilter } from '../models/dispatchers/dispatcherFilter';
import { AddDispatcherDto } from '../models/dispatchers/addDispatcherDto';
import { DispatcherDetailsDto } from '../models/dispatchers/dispatcherDetailsDto';
import { EditDispatcherDto } from '../models/dispatchers/editDispatcherDto';
import { DispatcherShortInfoDto } from '../models/dispatchers/dispatcherShortInfoDto';
import { FormDataUtils } from '../helpers/formDataUtils';
import { NotificationTypeDto } from '../models/notificationTypes/notificationTypeDto';

class DispatchersService {

    baseUrl: string = "/api/v1/dispatchers";

    getByFilter = (filter?: DispatcherlFilter) => {
        return api.get<AppResponse<PaginatedResponse<DispatcherShortInfoDto>>>(this.baseUrl, { params: filter });
    }

    getById = (id: number) => {
        return api.get<AppResponse<DispatcherDetailsDto>>(`${this.baseUrl}/${id}`);
    }

    add = (data: AddDispatcherDto) => {
        const formData = FormDataUtils.toFormData(data);
        return api.post<AppResponse<number>>(this.baseUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    edit = (data: EditDispatcherDto) => {
        const formData = FormDataUtils.toFormData(data);
        return api.put<AppResponse<number>>(`${this.baseUrl}/${data.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    getNotificationTypes = () => {
        return api.get<AppResponse<NotificationTypeDto[]>>(`${this.baseUrl}/notification-types`);
    }
}

export const dispatchersService = new DispatchersService();