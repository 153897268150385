import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Box, Typography, TextField, IconButton, InputAdornment, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { DateUtils } from '../../helpers/dateUtils';
import { medicalCheckupsService } from '../../services/medicalCheckupsService';
import { MedicalCheckupResult, MedicalCheckupResultUtil } from '../../models/enums/medicalCheckupResult';
import CompanyAutocomplete from '../companies/CompanyAutocomplete';
import { MedicalCheckupShortDto } from '../../models/medicalCheckups/medicalCheckupShortDto';
import { MedicalCheckupTypeUtil } from '../../models/enums/medicalCheckupType ';
import MedicalCheckupResultSelect from '../common/MedicalCheckupResultSelect';
import { useNavigate } from 'react-router-dom';
import { CompanyShortDto } from '../../models/companies/companyShortDto';
import { DatePickerFilter } from '../common/DatePickerFilter';
import { LookupDto } from '../../models/common/lookup.dto';
import { terminalsService } from '../../services/terminalsService';
import { MedicalCheckupStatus, MedicalCheckupStatusUtil } from '../../models/enums/medicalCheckupStatus';
import { RoleType } from '../../models/enums/roleType';
import { ShowForRoles } from '../common/ShowForRoles';

export const MedicalCheckupsHistoryTable = () => {
    const [data, setData] = useState<MedicalCheckupShortDto[]>([]);
    const [terminals, setTerminals] = useState<LookupDto[]>([]);
    const [page, setPage] = useState(0);
    const [dataCount, setDataCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const navigate = useNavigate();
    const [filter, setFilter] = useState({
        fromDate: null as Date | null,
        toDate: null as Date | null,
        medicResult: null as MedicalCheckupResult | null,
        fio: '',
        company: null as CompanyShortDto | null,
        terminalIds: [] as number[],
        status: 'all' as 'all' | MedicalCheckupStatus,
    });

    useEffect(() => {
        terminalsService.search('')
            .then(response => {
                setTerminals(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching terminals:', error);
            });
    }, []);

    useEffect(() => {
        const fetchData = () => {
            const statuses = filter.status === 'all' ? [MedicalCheckupStatus.Verdict, MedicalCheckupStatus.Signing] : [filter.status];
            medicalCheckupsService.getByFilter({
                fromDate: DateUtils.toServerString(filter.fromDate), // Dates will be send in UTC!
                toDate: DateUtils.toServerString(filter.toDate),
                medicResult: filter.medicResult,
                statuses: statuses,
                fio: filter.fio,
                companyId: filter.company ? filter.company.id : null,
                terminalIds: filter.terminalIds,
                page: page,
                pageSize: rowsPerPage,
            })
                .then(r => {
                    setData(r.data.data.items);
                    setDataCount(r.data.data.count);
                })
                .catch(e => console.log(e));
        };

        fetchData(); // Call it once immediately

        const interval = setInterval(fetchData, 30000); // Set up the refresh interval to 30 seconds

        return () => clearInterval(interval); // Clear interval on component unmount
    }, [page, rowsPerPage, filter]);

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to the first page when rowsPerPage changes
    };

    const filterChanged = (name: string, value: any) => {
        setFilter({ ...filter, [name]: value });
    };

    const renderCheckupResult = (result: MedicalCheckupResult) => {
        const strValue = MedicalCheckupResultUtil.getStringValue(result);
        let color = result === MedicalCheckupResult.Rejected ? '#ec4a71' : '#5bba64';
        let backgroundColor = result === MedicalCheckupResult.Rejected ? '#fce4ea' : '#edfbee';

        return <Box display="inline-block" color={color} sx={{backgroundColor: {backgroundColor}, borderRadius: "25px", paddingLeft: "15px", paddingRight: "15px"}}>{strValue}</Box>;
    };

    const renderCheckupStatus = (result: MedicalCheckupStatus) => {
        const strValue = MedicalCheckupStatusUtil.getStringValue(result);
        let color = result === MedicalCheckupStatus.SigningError ? '#ec4a71' : '#5bba64';
        let backgroundColor = result === MedicalCheckupStatus.SigningError ? '#fce4ea' : '#edfbee';

        return <Box display="inline-block" color={color} sx={{backgroundColor: {backgroundColor}, borderRadius: "25px", paddingLeft: "15px", paddingRight: "15px"}}>{strValue}</Box>;
    };

    function handleTerminalFilterChanged(e: { target: { name: string; value: any; }; }): void {
         filterChanged(e.target.name, e.target.value)
    }

    return (
        <Box>
            <Box display="flex" alignItems="center" marginBottom={1} mt={2.5}>
                <DatePickerFilter
                    label="Начало периода"
                    name="fromDate"
                    value={filter.fromDate}
                    onChange={(value, name) => filterChanged(name, value)}
                    style={{ width: 200, marginRight: 10, marginTop: 4 }}
                />

                <DatePickerFilter
                    label="Конец периода"
                    name="toDate"
                    value={filter.toDate}
                    onChange={(value, name) => filterChanged(name, value)}
                    style={{ width: 200, marginRight: 10, marginTop: 4 }}
                />

                <TextField
                    label="ФИО сотрудника"
                    name="fio"
                    value={filter.fio}
                    onChange={(e: { target: { name: string; value: any; }; }) => filterChanged(e.target.name, e.target.value)}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    style={{ width: 200, marginRight: 10 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setPage(0)}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                        style: {
                            borderRadius: "10px",
                        }
                    }}
                />
            </Box>
            <Box display="flex" alignItems="center" marginBottom={1}>

                <MedicalCheckupResultSelect
                    name={'medicResult'}
                    value={filter.medicResult !== null ? filter.medicResult : undefined}
                    onChange={(e) => filterChanged(e.target.name, e.target.value)}
                />

                <CompanyAutocomplete
                    company={filter.company}
                    onCompanyChange={(e) => filterChanged('company', e)}
                    style={{ width: 200, marginRight: 10 }}
                />

                <TextField
                    select
                    label="Терминал"
                    name="terminalIds"
                    value={filter.terminalIds}
                    onChange={handleTerminalFilterChanged}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    style={{ width: 200, marginRight: 10 }}     
                    SelectProps={{ multiple: true, style: {
                        borderRadius: "10px",
                    }}}              
                >
                    {terminals.map((t) => (
                        <MenuItem key={t.id} value={t.id}>
                            {t.name}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    select
                    label="Статус"
                    name="status"
                    value={filter.status}
                    onChange={(e) => filterChanged(e.target.name, e.target.value)}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    style={{ width: 200, marginRight: 10 }}
                    SelectProps={{ style: { borderRadius: "10px" } }}
                >
                    <MenuItem value="all">Все</MenuItem>
                    <MenuItem value={MedicalCheckupStatus.Verdict}>{MedicalCheckupStatusUtil.getStringValue(MedicalCheckupStatus.Verdict)}</MenuItem>
                    <MenuItem value={MedicalCheckupStatus.Signing}>{MedicalCheckupStatusUtil.getStringValue(MedicalCheckupStatus.Signing)}</MenuItem>
                </TextField>

            </Box>
            <TableContainer sx={{ '.MuiTableCell-root': { py: 1.5 } }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>№</Typography></TableCell>
                            <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>ФИО сотрудника</Typography></TableCell>
                            <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>Клиент</Typography></TableCell>
                            <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>Тип осмотра</Typography></TableCell>
                            <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>Дата</Typography></TableCell>
                            <ShowForRoles roles={[RoleType.Admin, RoleType.Medic]}>
                                <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>Результат авто</Typography></TableCell>
                            </ShowForRoles>
                            <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>Результат медика</Typography></TableCell>
                            <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>Статус</Typography></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item) => (
                            <TableRow
                                key={item.id}
                                onClick={() => window.open(`/medical-checkups/${item.id}`)}
                                sx={{ '&:hover': { cursor: 'pointer', backgroundColor: 'rgba(0, 0, 0, 0.08)' } }}
                            >
                                <TableCell sx={{ fontWeight: "bold" }}>{item.id}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{item.fio}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{item.company.name}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{MedicalCheckupTypeUtil.getStringValue(item.type)}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{DateUtils.formatDateTime(item.date)}</TableCell>
                                <ShowForRoles roles={[RoleType.Admin, RoleType.Medic]}>
                                    <TableCell sx={{ fontWeight: "bold" }}>{renderCheckupResult(item.autoResult)}</TableCell>
                                </ShowForRoles>
                                <TableCell sx={{ fontWeight: "bold" }}>{renderCheckupResult(item.medicResult)}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{renderCheckupStatus(item.status)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography>Всего: {dataCount}</Typography>
                <TablePagination
                    rowsPerPageOptions={[15, 30, 50]}
                    component="div"
                    count={dataCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event: any, page: number) => setPage(page)}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={"Строк на странице:"}
                    labelDisplayedRows={({ from, to, count }: any): string => `Показано ${from}-${to} из ${count}`}
                />
            </Box>
        </Box>
    );
};