import api from './api';
import {AppResponse} from "../models/common/app-response";
import { CompanyShortDto } from '../models/companies/companyShortDto';
import { DepartmentShortDto } from '../models/departments/departmentShortDto';

class CompaniesService {

    baseUrl: string = "/api/v1/companies";

    search = (companyName: string) => {
        return api.get<AppResponse<CompanyShortDto[]>>(`${this.baseUrl}/search?companyName=${companyName}`);
    }

    getDepartments = (companyId: number) => {
        return api.get<AppResponse<DepartmentShortDto[]>>(`${this.baseUrl}/${companyId}/departments`);
    }

    getDepartmentsByCompanyIds = (companyIds: number[]) => {
        return api.post<AppResponse<DepartmentShortDto[]>>(`${this.baseUrl}/departments`, companyIds);
    }
}

export const companiesService = new CompaniesService();