import React, { useEffect, useState } from 'react';
import { Box, Typography, MenuItem, TextField, Stack, FormGroup, Autocomplete, Divider } from '@mui/material';
import { Gender } from '../../../models/enums/gender';
import { companiesService } from '../../../services/companiesService';
import { ConfirmDialog } from '../../common/ConfirmDialog';
import { driversService } from '../../../services/driversService';
import { UserUtils } from '../../../helpers/userUtils';
import { DepartmentShortDto } from '../../../models/departments/departmentShortDto';
import { Controller, useFormContext } from 'react-hook-form';
import { CompanyShortDto } from '../../../models/companies/companyShortDto';
import { FileInputFormControl } from '../../common/form/FileInputFormControl';
import { DatePickerFormControl } from '../../common/form/DatePickerFormControl';
import { TextFieldFormControl } from '../../common/form/TextFieldFormControl';
import { EditDriverFormDto } from '../../../models/drivers/editDriverFormDto';
import ImagePreview from '../../common/ImagePreview';
import AcceptLabel from '../../common/labels/AcceptLabel';
import DenyLabel from '../../common/labels/DenyLabel';
import TmFullButton from '../../common/buttons/TmFullButton';

interface EditPersonalDataTabProps {
    setError: (message: string) => void;
    onRefresh?: () => void;
    isAdding?: boolean
}

export const EditPersonalDataTab: React.FC<EditPersonalDataTabProps> = ({ setError, isAdding, onRefresh }) => {
    const { control, getValues, setValue, formState: { errors }, watch } = useFormContext<EditDriverFormDto>();
    const [departments, setDepartments] = useState<DepartmentShortDto[]>([]);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [companies, setCompanies] = useState<CompanyShortDto[]>([]);

    useEffect(() => {
        companiesService.search('')
            .then(r => setCompanies(r.data.data))
            .catch(e => console.log(e));
    }, []);

    const watchedCompany = watch("company");
    useEffect(() => {
        if (watchedCompany && watchedCompany.id) {
            companiesService.getDepartments(watchedCompany.id)
                .then(r => {
                    setDepartments(r.data.data);

                    // Keep only previously selected departments that are still valid
                    const currentDepartmentIds = getValues('departmentIds');
                    const validDepartmentIds = r.data.data
                        .filter(dept => currentDepartmentIds!.includes(dept.id))
                        .map(dept => dept.id);

                    setValue('departmentIds', validDepartmentIds);
                })
                .catch(e => console.log(e));
        } else {
            // If no company is selected, clear the departments list
            setDepartments([]);
            setValue('departmentIds', []);
        }
    }, [watchedCompany, setValue]);

    function handleDismiss(): void {
        setOpenConfirmDialog(false);
        const driverId = getValues("id");
        if (driverId) {
            driversService.dismiss(driverId)
                .then(() => {
                    setValue("isDismissed", true);
                    if (onRefresh) {
                        onRefresh();
                    }
                })
                .catch(e => {
                    console.log(e);
                    setError(e.message);
                });
        }
    }

    const photoFile = watch("user.photoFile");
    const photoUrl = watch("user.photoUrl");

    return (
        <Box>
            <FormGroup>
                <Typography variant='h6' fontWeight="bold" mb="10px">Клиенты и объекты</Typography>
                <Stack spacing={2} direction="column">
                    <Controller
                        name='company'
                        control={control}
                        rules={{ required: 'Поле обязательно для заполнения' }}
                        render={({ field }) => (
                            <Autocomplete
                                size="small"
                                {...field}
                                onChange={(event, item) => field.onChange(item)}
                                value={field.value}
                                options={companies}
                                getOptionLabel={(item) => (item?.name ?? "")}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                noOptionsText="Не найдено"
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label='Клиент'
                                        error={Boolean(errors.company)}
                                        helperText={errors.company?.message}
                                        placeholder="Введите от 3х букв"
                                        required
                                    />
                                )}
                            />
                        )}
                    />
                    <Controller
                        name='departmentIds'
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                select
                                label="Объекты"
                                size="small"
                                SelectProps={{
                                    multiple: true,
                                    renderValue: (value) => {
                                        const selected = value as number[];
                                        if (selected.length > 2) {
                                            return `${selected.length} выбрано...`;
                                        }
                                        return selected.map((id) => departments.find(d => d.id === id)?.name).join(', ');
                                    },
                                }}
                                error={Boolean(errors.departments)}
                                helperText={errors.departments?.message}
                            >
                                {departments.map((d) => (
                                    <MenuItem key={d.id} value={d.id}>
                                        {d.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                </Stack>
            </FormGroup>
            <Divider sx={{ my: 1, borderBottomWidth: 0 }} />
            {!isAdding && <FormGroup>
                <Typography variant='h6' fontWeight="bold" mb="10px">Статус</Typography>
                <Stack spacing={2} direction="column">
                    <Box display="flex">
                        <Box style={{ width: '50%' }} pt={1}>
                            {getValues("isDismissed")
                                ? <DenyLabel>Уволен</DenyLabel>
                                : <AcceptLabel>Работает</AcceptLabel>}
                        </Box>
                        <Box style={{ width: '50%', textAlign: 'end' }}>
                            <TmFullButton onClick={() => setOpenConfirmDialog(true)} disabled={!(getValues("id") && !getValues("isDismissed"))}>
                                Уволить
                            </TmFullButton>
                        </Box>
                    </Box>
                </Stack>
            </FormGroup>}

            <FormGroup>
                <Typography variant='h6' fontWeight="bold" mb="10px">Личные данные</Typography>
                <Stack spacing={2} direction="column">
                    <TextFieldFormControl
                        name="user.lastName"
                        label="Фамилия"
                        required
                    />
                    <Box display="flex">
                        <TextFieldFormControl
                            name="user.firstName"
                            label="Имя"
                            required
                            style={{ marginRight: 10 }}
                        />
                        <TextFieldFormControl
                            name="user.middleName"
                            label="Отчество"
                            required
                        />
                    </Box>
                    <Box display="flex">
                        <Controller
                            name="user.gender"
                            control={control}
                            rules={{ required: 'Поле обязательно для заполнения' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    size="small"
                                    required
                                    select
                                    label="Пол"
                                    defaultValue=''
                                    error={Boolean(errors.user?.gender)}
                                    helperText={errors.user?.gender?.message}
                                    style={{ width: '50%', marginRight: 10 }}
                                >
                                    <MenuItem value='' disabled>Выберите пол</MenuItem>
                                    <MenuItem key={Gender.Male} value={Gender.Male}>Мужской</MenuItem>
                                    <MenuItem key={Gender.Female} value={Gender.Female}>Женский</MenuItem>
                                </TextField>
                            )}
                        />

                        <DatePickerFormControl
                            name="user.birthDate"
                            label={"Дата рождения"}
                            style={{ width: '50%' }}
                            required
                        />
                    </Box>
                </Stack>
            </FormGroup>
            <Divider sx={{ my: 1, borderBottomWidth: 0 }} />
            <FormGroup>
                <Typography variant='h6' fontWeight="bold" mb={0.5}>Документы</Typography>
                <Stack spacing={0} direction="column">
                    <Typography fontWeight="bold" mb={2}>Водительское удостоверение</Typography>
                    <Box display="flex" sx={{ mb: 2 }}>
                        <TextFieldFormControl
                            name="licenceSerial"
                            label="Серия"
                            style={{ width: '33%', marginRight: 10 }}
                        />
                        <TextFieldFormControl
                            name="licenceNumber"
                            label="Номер"
                            style={{ width: '33%', marginRight: 10 }}
                        />

                        <DatePickerFormControl
                            name="licenceIssued"
                            label={"Дата выдачи"}
                            style={{ width: '33%' }}
                        />

                    </Box>
                    <Typography fontWeight="bold" mb={2}>Снилс</Typography>
                    <TextFieldFormControl
                        name="snils"
                        label="№ Снилс"
                        style={{ marginBottom: 10 }}
                    />
                    <Typography fontWeight="bold" mb={2}>Паспорт</Typography>
                    <Box display="flex">
                        <TextFieldFormControl
                            name="passportSerial"
                            label="Серия"
                            style={{ marginRight: 10 }}
                        />
                        <TextFieldFormControl
                            name="passportNumber"
                            label="Номер"
                        />
                    </Box>
                </Stack>
            </FormGroup>
            <Divider sx={{ my: 1, borderBottomWidth: 0 }} />
            <Typography variant='h6' fontWeight="bold" mb={2}>Служебная информация</Typography>
            <Stack spacing={2} direction="column">
                <TextFieldFormControl
                    name="employeeNumber"
                    label="Табельный номер"
                    required
                    style={{ marginBottom: 10 }}
                />
            </Stack>

            <Typography variant='h6' fontWeight="bold" mb={0.5}>Фото водителя</Typography>
            <Stack spacing={2} direction="column">
                <FileInputFormControl name="user.photoFile" />
                {(photoFile || photoUrl) && (
                    <ImagePreview
                        file={photoFile}
                        url={photoUrl}
                        altText="Driver Photo"
                        onRemove={() => {
                            setValue("user.photoFile", undefined);
                            setValue("user.photoUrl", undefined);
                        }}
                    />
                )}
            </Stack>
            <ConfirmDialog
                open={openConfirmDialog}
                title="Увольнение сотрудника"
                content={
                    <>
                        Вы уверены, что хотите уволить водителя
                        <Typography variant="body1" align="center" fontWeight="bold">
                            {UserUtils.getFio(getValues("user"))}
                        </Typography>
                    </>
                }
                onClose={() => setOpenConfirmDialog(false)}
                onConfirm={handleDismiss}
            />
        </Box>
    );
};