import React from 'react';
import { Box } from '@mui/material';
import TmArrow from '../../../icons/TmArrow';
import { MedicalIndicatorDto } from '../../../models/medicalIndicators/medicalIndicatorDto';
import { MedicalIndicatorsEnum } from '../../../models/enums/medicalIndicatorsEnum';
import { MedicalCheckupDto } from '../../../models/medicalCheckups/medicalCheckupDto';

export enum TmMedParamsType {
    bloodPressure,
    pulse,
    alcohol,
    temperature,
}

interface TmMedParamsLabelProps {
    type: TmMedParamsType;
    medicalCheckup: MedicalCheckupDto;
  }

const TmMedParamsLabel: React.FC<TmMedParamsLabelProps> = ({ type, medicalCheckup }) => {
    switch (type) {
        case TmMedParamsType.bloodPressure:
            {
                const isOk = medicalCheckup.indicators.upperPressureParam === MedicalIndicatorsEnum.Ok && medicalCheckup.indicators.lowerPressureParam === MedicalIndicatorsEnum.Ok;
                const isArrowUp = medicalCheckup.indicators.upperPressureParam === MedicalIndicatorsEnum.High || medicalCheckup.indicators.lowerPressureParam === MedicalIndicatorsEnum.High;
                const isArrowDown = medicalCheckup.indicators.upperPressureParam === MedicalIndicatorsEnum.Low || medicalCheckup.indicators.lowerPressureParam === MedicalIndicatorsEnum.Low;

                if (isOk) {
                    return (
                        <Box color="#5bba64" sx={{ fontWeight: "bold"}}>{medicalCheckup.indicators.upperPressure && medicalCheckup.indicators.lowerPressure
                            ? `${medicalCheckup.indicators.upperPressure}/${medicalCheckup?.indicators.lowerPressure}`
                            : ''}</Box>
                    );
                }

                return (
                    <Box color="#ec4a71" sx={{ fontWeight: "bold"}}>
                        {isArrowUp ? <TmArrow stroke="#EC4A71" fill="#EC4A71" arrowUp={true}/> : null }
                        {isArrowDown ? <TmArrow stroke="#EC4A71" fill="#EC4A71" arrowUp={false}/> : null}
                        {medicalCheckup.indicators.upperPressure && medicalCheckup.indicators.lowerPressure
                            ? `${medicalCheckup.indicators.upperPressure}/${medicalCheckup?.indicators.lowerPressure}`
                            : ''}
                    </Box>
                );
            }
        case TmMedParamsType.pulse:
            {
                const isOk = medicalCheckup.indicators.pulseParam === MedicalIndicatorsEnum.Ok;
                const isArrowUp = medicalCheckup.indicators.pulseParam === MedicalIndicatorsEnum.High;

                if (isOk) {
                    return (
                        <Box color="#5bba64" sx={{ fontWeight: "bold"}}>{medicalCheckup.indicators.pulse}</Box>
                    );
                }

                return (
                    <Box color="#ec4a71" sx={{ fontWeight: "bold"}}>
                        <TmArrow stroke="#EC4A71" fill="#EC4A71" arrowUp={isArrowUp}/>
                        {medicalCheckup.indicators.pulse}
                    </Box>
                );
            }
        case TmMedParamsType.alcohol:
            {
                const isOk = medicalCheckup.indicators.alcoholParam === MedicalIndicatorsEnum.Ok;

                if (isOk) {
                    return (
                        <Box color="#5bba64" sx={{ fontWeight: "bold"}}>Нет</Box>
                    );
                }

                if (medicalCheckup.terminal?.version === "v1" || !(medicalCheckup.indicators.alcohol)) { // Либо первая версия терминала, либо колличественный показатель не передан
                    return (
                        <Box color="#ec4a71" sx={{ fontWeight: "bold"}}>
                            Да
                        </Box>
                    );
                }

                return (
                    <Box color="#ec4a71" sx={{ fontWeight: "bold"}}>
                        <TmArrow stroke="#EC4A71" fill="#EC4A71" arrowUp={true}/>
                        {medicalCheckup.indicators.alcohol}
                    </Box>
                );
            }
        case TmMedParamsType.temperature:
            {
                const isOk = medicalCheckup.indicators.temperatureParam === MedicalIndicatorsEnum.Ok;
                const isArrowUp = medicalCheckup.indicators.temperatureParam === MedicalIndicatorsEnum.High;

                if (isOk) {
                    return (
                        <Box color="#5bba64" sx={{ fontWeight: "bold"}}>{medicalCheckup.indicators.temperature}</Box>
                    );
                }

                return (
                    <Box color="#ec4a71" sx={{ fontWeight: "bold"}}>
                        <TmArrow stroke="#EC4A71" fill="#EC4A71" arrowUp={isArrowUp}/>
                        {medicalCheckup.indicators.temperature}
                    </Box>
                );
            }
        default:
            return null;
    }
 
}

export default TmMedParamsLabel;
