import React, { useEffect, useState } from 'react';
import { ViewPersonalDataTab } from './ViewPersonalDataTab';
import { ViewMedicalDataTab } from './ViewMedicalDataTab';
import { useNavigate, useParams } from 'react-router-dom';
import { driversService } from '../../../services/driversService';
import { Grid, Typography, IconButton, Box, Avatar } from '@mui/material';
import { DriverDto } from '../../../models/drivers/driverDto';
import { EditDriverModal } from '../edit/EditDriverModal';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Spinner from '../../common/Spinner';
import { ROUTES } from '../../../constants/routes';
import StyledTabs from '../../common/tabs/StyledTabs';
import StyledTab from '../../common/tabs/StyledTab';

export const DriverDetails: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [driver, setDriver] = useState<DriverDto>();
  const [isEditPopupOpen, setEditPopupOpen] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    const driverId = Number(params.id);

    driversService.getById(driverId)
      .then(r => setDriver(r.data.data))
      .catch(e => {
        console.error(e);
        if (e.response.status === 404) {
          navigate(ROUTES.auth.notFound);
        }
      })
      .finally(() => setLoading(false));
  }, [params.id]);

  const refreshDriverData = () => {
    if (!params.id) return;

    const driverId = Number(params.id);
    setLoading(true);

    driversService.getById(driverId)
      .then(r => setDriver(r.data.data))
      .catch(e => console.error(e))
      .finally(() => setLoading(false));
  };


  function handleEditPopupToggle(): void {
    setEditPopupOpen(!isEditPopupOpen);
  }

  return (
    <Box>
      {loading
        ? <Spinner />
        : (<Box>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={2}>
              <IconButton onClick={() => navigate(-1)} sx={{ my: 2 }}>
                  <Avatar sx={{ backgroundColor: "white", border: 1, borderColor: "#9588e8" }}>
                      <ChevronLeftIcon sx={{ color: '#9588e8' }} />
                  </Avatar>
              </IconButton>     
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h6" align="center">Данные водителя</Typography>
            </Grid>
            <Grid item xs={2} /> {/* Empty Grid item for balancing */}
          </Grid>
          <StyledTabs            
            value={currentTab}
            onChange={handleTabChange}
            sx={{
              '.MuiTabs-flexContainer': {
                display: 'flex',
                width: '100%',
              },
              '.MuiTab-root': {
                flex: '1',
              },
            }}
          >
            <StyledTab label="Общая информация" />
            <StyledTab label="Нормы и медосмотры" />
          </StyledTabs>
          {currentTab === 0 && <ViewPersonalDataTab driver={driver!} onEditClick={handleEditPopupToggle} />}
          {currentTab === 1 && <ViewMedicalDataTab driver={driver!} onEditClick={handleEditPopupToggle} onRefresh={refreshDriverData} />}
          {isEditPopupOpen && <EditDriverModal onClose={handleEditPopupToggle} onRefresh={refreshDriverData} isAdding={false} driver={driver!} />}
        </Box>)}
    </Box>
  );
};
