import { Link, Grid, Typography, Divider, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Modal, Dialog, DialogTitle, DialogContent, Avatar } from "@mui/material";
import Spinner from "../../common/Spinner";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MedicalCheckupDto, emptyMedicalCheckup } from "../../../models/medicalCheckups/medicalCheckupDto";
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import { NotAllowedDialog } from "../NotAllowedDialog";
import { MedicalCheckupResult, MedicalCheckupResultUtil } from "../../../models/enums/medicalCheckupResult";
import { ROUTES } from "../../../constants/routes";
import { DateUtils } from "../../../helpers/dateUtils";
import { UserUtils } from "../../../helpers/userUtils";
import { Gender, getGenderString } from "../../../models/enums/gender";
import { MedicalCheckupTypeUtil } from "../../../models/enums/medicalCheckupType ";
import ReactPlayer from "react-player";
import { DriverMedicalCheckupsHistoryTable } from "./DriverMedicalCheckupsHistoryTable";
import maleAvatar from '../../../assets/images/male-avatar.png';
import femaleAvatar from '../../../assets/images/female-avatar.png';
import { MedicalCheckupStatus } from "../../../models/enums/medicalCheckupStatus";
import AlertModal from "../../common/AlertModal";
import DenyButton from "../../common/buttons/DenyButton";
import AcceptButton from "../../common/buttons/AcceptButton";
import DenyLabel from "../../common/labels/DenyLabel";
import AcceptLabel from "../../common/labels/AcceptLabel";
import TmMedParamsLabel, { TmMedParamsType } from "../../common/labels/TmMedParamsLabel";
import { RoleType } from "../../../models/enums/roleType";
import { ShowForRole } from "../../common/ShowForRole";
import { medicalCheckupsService } from "../../../services/medicalCheckupsService";
import { permissionService } from "../../../services/permissionService";
import { MedicalIndicatorsEnum } from "../../../models/enums/medicalIndicatorsEnum";
import { ShowForRoles } from "../../common/ShowForRoles";
import { MedicalCheckupVerdictDto } from "../../../models/medicalCheckups/medicalCheckupVerdictDto";

export const MedicalCheckupDetails = () => {
    const [loading, setLoading] = useState(true);
    const [spinnerText, setSpinnerText] = useState('');
    const [error, setError] = useState('');
    const [openNotAllowedDialog, setOpenNotAllowedDialog] = useState(false);
    const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
    const [medicalCheckupId, setMedicalCheckupId] = useState<number>();
    const [medicalCheckup, setMedicalCheckup] = useState<MedicalCheckupDto>(emptyMedicalCheckup);
    const navigate = useNavigate();
    const params = useParams();

    const fetchMedicalCheckup = useCallback(async (id: number) => {
        try {
            setLoading(true);
            const response = await medicalCheckupsService.getById(id);
            setMedicalCheckup(response.data.data);
            return response.data.data;
        } catch (e: any) {
            console.error(e);
            if (e.response && e.response.status === 404) {
                navigate(ROUTES.auth.notFound);
            }
        } finally {
            setLoading(false);
        }
    }, [navigate]);

    useEffect(() => {
        const id = Number(params.id);
        if (!id) return;

        setMedicalCheckupId(id);

        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            medicalCheckupsService.unlock(id);
        };

        const fetchMedicalCheckupData = async () => {
            try {
                setLoading(true);
                const response = await medicalCheckupsService.getById(id);
                setMedicalCheckup(response.data.data);

                // Lock only on behalf of medic and only new medical checkups
                if (permissionService.hasRole(RoleType.Medic) && response.data.data.status === MedicalCheckupStatus.New) {
                    medicalCheckupsService.lock(id);
                    window.addEventListener('beforeunload', handleBeforeUnload);
                }
            } catch (e: any) {
                console.error(e);
                if (e.response && e.response.status === 404) {
                    navigate(ROUTES.auth.notFound);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchMedicalCheckupData();

        // Unmount component function
        return () => {
            if (permissionService.hasRole(RoleType.Medic) && medicalCheckup && medicalCheckup.status === MedicalCheckupStatus.New) {
                medicalCheckupsService.unlock(id);
            }
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [params.id, fetchMedicalCheckup, navigate]);

    const handleVerdict = (verdictData: MedicalCheckupVerdictDto) => {
        setLoading(true);
        setSpinnerText("Подписание...");
            Promise.resolve()
            .then(() => medicalCheckupsService.setVerdict(medicalCheckupId!, verdictData))
            .then(() => setSpinnerText("Успешное подписание!"))
            .then(() => navigate(ROUTES.medicalCheckups.listOfNew))
            .catch(e => {
                console.error(e);
                setError(e.response?.data?.message ?? "Непредвиденная ошибка");
            })
            .finally(() => setLoading(false));
    };

    const handleAllowed = () => {
        handleVerdict({
            result: MedicalCheckupResult.Approved
        });
    };
    
    const handleNotAllowed = (selectedReasons: number[], comment: string) => {
        setOpenNotAllowedDialog(false);
        handleVerdict({
            result: MedicalCheckupResult.Rejected,
            rejectReasons: selectedReasons,
            comment: comment
        });
    };

    function renderCheckupResult(result: MedicalCheckupResult) {
        const strValue = MedicalCheckupResultUtil.getStringValue(result);
        let color = result === MedicalCheckupResult.Rejected ? '#ec4a71' : '#5bba64';
        let backgroundColor = result === MedicalCheckupResult.Rejected ? '#fce4ea' : '#edfbee';

        return <Box display="inline-block" color={color} sx={{ backgroundColor: { backgroundColor }, borderRadius: "25px", paddingLeft: "15px", paddingRight: "15px", fontWeight: "bold" }}>{strValue}</Box>;
    };

    function renderComplaints(complaints: string[]) {
        if (complaints && complaints.length > 0) {
            return complaints.map((complaint, index) => (
                <span key={index}>&nbsp;<DenyLabel>{complaint}</DenyLabel></span>
            ))
        }
        return <AcceptLabel>Нет жалоб</AcceptLabel>;
    }

    const toggleHistoryModal = () => {
        setIsHistoryModalOpen(!isHistoryModalOpen);
    };

    const [open, setOpen] = useState(false);
    const [image, setImage] = useState("false");

    const handleClose = () => {
        setOpen(false);
    };

    const handleImage = (value: any) => {
        setImage(value);
        setOpen(true);
    };

    function renderSectionHeader(title: string, add: string = "", my: number = 1) {
        if (!!add) {
            add = ` ${add}`
            add = ` ${add}`
        }

        return (
            <Typography variant="h6" sx={{ fontWeight: 'bold', my: my }}>
                {title}{add}
            </Typography>
        );
    }

    function renderSectionItem(label: string, content: React.ReactNode, cn: string = "") {
        if (cn == "") {
            cn = "ltb"
        }

        return (
            <div>
                <Typography className={cn} variant="body1">{label}</Typography>
                &nbsp; <Typography className="rtb">{content}</Typography>
            </div>
        );
    }

    const MedicalCheckupTable = () => {
        return (
            <>
                <TableContainer sx={{ mr: 20, maxWidth: '95%', border: 0, px: 3, overflow: "hidden" }}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{ borderTop: 1, borderColor: "#e7e7ea" }}>
                                <TableCell className="grey">Давление</TableCell>
                                <TableCell className="grey">Пульс</TableCell>
                                <TableCell className="grey">Промилле</TableCell>
                                <TableCell className="grey">Температура</TableCell>
                                <ShowForRoles roles={[RoleType.Admin, RoleType.Medic]}>
                                    <TableCell className="grey">Результат авто</TableCell>
                                </ShowForRoles>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                    <TmMedParamsLabel type={TmMedParamsType.bloodPressure} medicalCheckup={medicalCheckup} />
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                    <TmMedParamsLabel type={TmMedParamsType.pulse} medicalCheckup={medicalCheckup} />
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                    <TmMedParamsLabel type={TmMedParamsType.alcohol} medicalCheckup={medicalCheckup} />
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                    <TmMedParamsLabel type={TmMedParamsType.temperature} medicalCheckup={medicalCheckup} />
                                </TableCell>
                                <ShowForRoles roles={[RoleType.Admin, RoleType.Medic]}>
                                    <TableCell sx={{ fontWeight: "bold" }}>{renderCheckupResult(medicalCheckup.autoResult)}</TableCell>
                                </ShowForRoles>
                            </TableRow>
                        </TableBody>
                    </Table>

                </TableContainer>
                <Dialog open={isHistoryModalOpen} onClose={toggleHistoryModal} maxWidth="lg">
                    <DialogTitle sx={{ textAlign: 'center' }}>
                        <Typography fontWeight='bold'>История медосмотров водителя</Typography>
                        <Typography fontWeight='bold'>{UserUtils.getFio(medicalCheckup.driver.user)}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Box>
                            <DriverMedicalCheckupsHistoryTable driverId={medicalCheckup.driver.id!} />
                        </Box>
                    </DialogContent>
                </Dialog>
            </>
        );
    };

    return (
        <div>
            {loading
                ? <Spinner text={spinnerText} />
                : (<Box>
                    <Grid container spacing={2} paddingTop={2}>
                        <Grid item xs={12} md={8}>
                            <Box className="gbr">
                                <Grid container paddingBottom={2} pl={2} pt={1}>
                                    <Grid item xs={3} md={3} textAlign={"center"}>
                                        <Typography className="grey">Фото сотрудника</Typography>
                                        {(medicalCheckup.driver.user?.photoUrl)
                                            ? (
                                                <img
                                                    src={medicalCheckup.driver.user?.photoUrl}
                                                    alt='Фото водителя'
                                                    onClick={(e) => handleImage(medicalCheckup.driver.user?.photoUrl)}
                                                    style={{ maxHeight: '140px', borderRadius: "25px" }}
                                                />
                                            )
                                            : (<Avatar
                                                alt="Фото водителя"
                                                src={(medicalCheckup.driver.user.gender === Gender.Male ? maleAvatar : femaleAvatar)}
                                                sx={{ width: 100, height: 100 }}
                                            />)}
                                    </Grid>
                                    <Grid item xs={3} md={3} textAlign={"center"}>
                                        <Typography className="grey">Фото осмотра</Typography>
                                        {(medicalCheckup.photoUrl) ? (
                                            <img
                                                src={medicalCheckup.photoUrl}
                                                alt='Фото медосмотра'
                                                onClick={(e) => handleImage(medicalCheckup.photoUrl)}
                                                style={{ maxHeight: '140px', borderRadius: "25px" }}
                                            />
                                        )
                                            : (<WallpaperIcon sx={{ fontSize: 100 }} />)}
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <Box sx={{ float: "left", clear: "left" }}>
                                            {renderSectionHeader(UserUtils.getFio(medicalCheckup.driver.user), "", 0)}
                                            {renderSectionItem("Табельный №", medicalCheckup.driver.employeeNumber, "ltb-p0")}
                                            {renderSectionItem("Пол", getGenderString(medicalCheckup.driver.user?.gender), "ltb-p0")}
                                            {renderSectionItem("Год рождения", `${DateUtils.getYear(medicalCheckup.driver.user?.birthDate)} (${DateUtils.getAge(medicalCheckup.driver.user?.birthDate)})`, "ltb-p0")}
                                            {renderSectionItem("Клиент", medicalCheckup.company.name, "ltb-p0")}
                                            {/* <hr style={{marginTop: 10, border: "none", height: "1px"}} color="#e5e5e9"></hr>
                                            {renderSectionItem("Ответственное лицо", "Иванов Алексей Александрович", "ltb-p0")}
                                            {renderSectionItem("", "+7 977 543-24-23")} */}
                                        </Box>
                                    </Grid>

                                    {/* Подпись */}
                                    <Grid item xs={2} md={2}>
                                        <Box pt={0} pr={2} sx={{ float: "right", clear: "right" }}>
                                            {/* <Box className="gbr" pt={1} pl={2} pb={1}> */}
                                            <Box pt={1} pl={2} pb={1}>
                                                <Box className="rtb" height={80}>{medicalCheckup.signatureUrl
                                                    ? (
                                                        <img
                                                            src={medicalCheckup.signatureUrl}
                                                            alt='Driver signature'
                                                            onClick={(e) => handleImage(medicalCheckup.signatureUrl)}
                                                            style={{ height: '80px', width: "auto", maxWidth: "200px" }}
                                                        />
                                                    )
                                                    : (<WallpaperIcon />)}</Box>

                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className="grey-block" marginTop={2}>
                                <Grid container spacing={2} paddingLeft={2} paddingBottom={2}>
                                    <Grid item xs={7} md={7}>
                                        {renderSectionHeader("Данные медосмотра")}
                                        {renderSectionItem("Тип осмотра", MedicalCheckupTypeUtil.getStringValue(medicalCheckup.type), "ltb-p0")}
                                    </Grid>
                                    <Grid item xs={5} md={5}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', my: 1, visibility: "hidden" }}>trim</Typography>
                                        {renderSectionItem("Дата осмотра",
                                            DateUtils.formatDateTime(medicalCheckup.startedAt) + " - " + DateUtils.formatTime(medicalCheckup.createdDate), "ltb-p0"
                                        )}
                                    </Grid>

                                </Grid>

                                <MedicalCheckupTable />


                                <Grid container spacing={2} paddingLeft={2} pb={1}>
                                    <Grid item xs={8} md={8}>
                                        <Link href="#" py={2} px={2} sx={{ float: "left" }} display="flex" fontWeight="bold" underline="none" color="#9588e8" onClick={toggleHistoryModal}>Посмотреть историю медосмотров</Link>
                                    </Grid>
                                    <ShowForRole role={RoleType.Medic}>
                                        <Grid item xs={4} md={4}>
                                            {(medicalCheckup.status === MedicalCheckupStatus.New || medicalCheckup.status === MedicalCheckupStatus.SigningError) && (
                                                <Box paddingTop={1} px={2} sx={{ display: 'flex', float: 'right' }}>
                                                    <Box px={2}>
                                                        <DenyButton onClick={() => setOpenNotAllowedDialog(true)}>Недопуск</DenyButton>
                                                    </Box>
                                                    <Box >
                                                        <AcceptButton onClick={handleAllowed} disabled={medicalCheckup.indicators.alcoholParam === MedicalIndicatorsEnum.High}>Допуск</AcceptButton>
                                                    </Box>
                                                </Box>)}
                                        </Grid>
                                    </ShowForRole>
                                </Grid>

                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4} marginLeft={0}>
                            <Box className="gbr">
                                <Box px={2} pb={2}>
                                    {renderSectionHeader("Наличие жалоб")}
                                    {renderComplaints(medicalCheckup.complaints)}
                                </Box>
                            </Box>

                            {/* Справки */}
                            <Box p={1}></Box>
                            <Box className="gbr">
                                <Grid container spacing={2} p={2}>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="h6" fontWeight='bold'>Справки</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        {
                                            // medicalCheckup.driver.healthCertificates && medicalCheckup.driver.healthCertificates.length > 0
                                            //     ? <Button variant="contained" color="success" sx={{ margin: 1 }}>Посмотреть все</Button>
                                            //     : ""
                                        }
                                    </Grid>
                                    {
                                        medicalCheckup.driver.healthCertificates && medicalCheckup.driver.healthCertificates.length > 0
                                            ? medicalCheckup.driver.healthCertificates.map((h, index) => (
                                                <Grid item xs={6} md={6} key={index} height={302}>
                                                    {(h.photoFile || h.photoUrl) && (
                                                        <img
                                                            src={h.photoUrl}
                                                            alt='Health Certificate'
                                                            onClick={(e) => handleImage(h.photoUrl)}
                                                            style={{ maxHeight: '186px', borderRadius: "25px" }}
                                                        />

                                                    )}
                                                    <Typography variant="body1" fontWeight="bold">{h.type.name}</Typography>
                                                    <Typography variant="body1" fontWeight="bold" className="grey">Дата выдачи:</Typography>
                                                    <Typography variant="body1" fontWeight="bold">{DateUtils.formatDate(h.issued)}</Typography>
                                                </Grid>
                                            ))
                                            : <Typography variant="body1">{"<Нет справок>"}</Typography>
                                    }
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} paddingTop={0}>
                        <Grid item xs={12} md={12} marginLeft={0}>
                            <Box pl={2} pr={2} pt={2}>
                                <Grid container spacing={2} pb={2}>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="h6" fontWeight='bold' paddingBottom="10px">Запись осмотра</Typography>
                                        <div className='player-wrapper'>
                                            <ReactPlayer
                                                className='react-player'
                                                width="100%"
                                                height="100%"
                                                url={medicalCheckup.videoUrl}
                                                controls={true}
                                                playing={true} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="h6" fontWeight='bold' paddingBottom="10px" sx={{ visibility: "hidden" }}>trim</Typography>
                                        <div className='player-wrapper'>
                                            <ReactPlayer
                                                className='react-player'
                                                width="100%"
                                                height="100%"
                                                url={medicalCheckup.video2Url}
                                                controls={true}
                                                playing={true} />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={4} marginLeft={0} pr={0}>
                                        {/* Норма медицинских показателей */}
                                        <Typography variant="h6" fontWeight='bold' paddingBottom="10px">Норма медицинских показателей</Typography>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <Box className="grey-block" p={2} pt={4}>
                                                    <Typography variant="body1" fontWeight="bold">Давление верхнее</Typography>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6} md={6}>
                                                            <Typography variant="body1" className="grey">Мин.</Typography>
                                                            <Typography variant="body1" fontWeight='bold'>{medicalCheckup.driver.defaultIndicators.upperPressureMin}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} md={6}>
                                                            <Typography variant="body1" className="grey">Макс.</Typography>
                                                            <Typography variant="body1" fontWeight='bold'>{medicalCheckup.driver.defaultIndicators.upperPressureMax}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box className="grey-block" p={2} pt={4}>
                                                    <Typography variant="body1" fontWeight="bold">Давление нижнее</Typography>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={6}>
                                                            <Typography variant="body1" className="grey">Мин.</Typography>
                                                            <Typography variant="body1" fontWeight='bold'>{medicalCheckup.driver.defaultIndicators.lowerPressureMin}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Typography variant="body1" className="grey">Макс.</Typography>
                                                            <Typography variant="body1" fontWeight='bold'>{medicalCheckup.driver.defaultIndicators.lowerPressureMax}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={2} pt={2}>

                                            <Grid item xs={12} md={6}>
                                                <Box className="grey-block" p={2}>
                                                    <Typography variant="body1" fontWeight="bold">Пульс</Typography>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={6}>
                                                            <Typography variant="body1" className="grey">Мин.</Typography>
                                                            <Typography fontWeight='bold'>{medicalCheckup.driver.defaultIndicators.pulseMin}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Typography variant="body1" className="grey">Макс.</Typography>
                                                            <Typography fontWeight='bold'>{medicalCheckup.driver.defaultIndicators.pulseMax}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <Box className="grey-block" p={2}>
                                                    <Typography variant="body1" fontWeight="bold">Температура</Typography>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={6}>
                                                            <Typography variant="body1" className="grey">Макс.</Typography>
                                                            <Typography fontWeight='bold'>{medicalCheckup.driver.defaultIndicators.temperatureMax}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            {/* {empty for grid formatting} */}
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Box>
                        </Grid>

                        <Grid item xs={12} md={12} marginLeft={0}>

                            {/* Клиент */}
                            <Box className="gbr" pl={2} pb={2}>
                                <Grid container spacing={2} paddingTop={2}>
                                    <Grid item xs={12} md={8} marginLeft={0}>
                                        {renderSectionHeader("Клиент", medicalCheckup.company.name)}
                                        <Typography className="ltb" variant="body1" pb={2.5}>Диспетчеры</Typography>

                                        <Grid container spacing={2} paddingLeft={2} paddingBottom={1} textAlign="center">
                                            {medicalCheckup.company.dispatchers && medicalCheckup.company.dispatchers.length > 0
                                                ? medicalCheckup.company.dispatchers.map((d, index) => (
                                                    <Box key={index} className="vbr" px={2} py={1} m={0.5}>
                                                        <Typography variant="body1" fontWeight="bold">{d.firstName}</Typography>
                                                        <Typography variant="body1">{d.phoneNumber}</Typography>
                                                    </Box>
                                                ))
                                                : <Typography variant="body1">{"<Нет диспетчеров>"}</Typography>}
                                        </Grid>

                                        <Typography className="grey" display="inline-block" pb={3}>Объекты клиента</Typography>

                                        <Grid container spacing={2} paddingLeft={2} paddingBottom={1} textAlign="center">
                                            {medicalCheckup.driver.departments && medicalCheckup.driver.departments.length > 0
                                                ? medicalCheckup.driver.departments.map((d, index) => (

                                                    <Box key={index} className="gbr" px={2} py={1} m={0.5}>
                                                        <Typography variant="body1" key={index} fontWeight='bold'>{d.name}</Typography>
                                                    </Box>
                                                ))
                                                : <Typography variant="body1">{"<Нет объектов>"}</Typography>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box p={1}></Box>

                            {/* Результат */}
                            {medicalCheckup.status === MedicalCheckupStatus.Verdict && (
                                <Box className="gbr" pl={2} pb={2}>

                                    <div>
                                        {renderSectionHeader("Результат")}
                                        {renderSectionItem("Медик", <span style={{ textDecoration: 'underline' }}>{UserUtils.getFio(medicalCheckup.medic?.user)}</span>)}
                                        {renderSectionItem("Результат (автоматический)", renderCheckupResult(medicalCheckup.autoResult))}
                                        {renderSectionItem("Результат (медик)", renderCheckupResult(medicalCheckup.medicResult))}
                                        {renderSectionItem("Причины не допуска", medicalCheckup.rejectReasons ? medicalCheckup.rejectReasons.map((r, index) => r.name).join(', ') : "-")}
                                        {renderSectionItem("Комментарий", medicalCheckup.notAllowedDescription)}
                                    </div>
                                </Box>)}
                            {medicalCheckup.status === MedicalCheckupStatus.Verdict && (<Box p={1}></Box>)}

                            <Box className="gbr" pl={2} pb={2}>
                                {renderSectionHeader("Дополнительная информация")}
                                {renderSectionItem("Время результата (по МСК)", medicalCheckup.signedDate ? DateUtils.formatDateTime(medicalCheckup.signedDate) : DateUtils.formatDateTime(medicalCheckup.endAt))}

                                {/* медосмотр может производиться без терминала! */}
                                {renderSectionItem("Терминал", medicalCheckup.terminal ? medicalCheckup.terminal.name : "<Нет терминала>")}
                                {renderSectionItem("Адрес терминала", medicalCheckup.terminal?.address ? medicalCheckup.terminal?.address : "<Нет адреса>")}
                            </Box>
                        </Grid>
                    </Grid>


                    <Grid container spacing={2} padding={2}>
                        <Grid item xs={6} md={6}>

                            <Modal
                                className='modal-img'
                                open={open}
                                onClose={handleClose}
                                closeAfterTransition
                            >
                                    <img
                                        src={image}
                                        alt='Health Certificate'
                                        style={{ maxHeight: "90%", maxWidth: "90%" }}
                                    />
                            </Modal>
                        </Grid>
                    </Grid>
                    <Divider />

                </Box>)}
            <NotAllowedDialog
                medicalCheckupId={medicalCheckupId!}
                open={openNotAllowedDialog}
                onSubmit={handleNotAllowed}
                onClose={() => setOpenNotAllowedDialog(false)}
            />
            <AlertModal
                open={Boolean(error)}
                handleClose={() => setError('')}
                text={error}
            />
        </div>
    );
}