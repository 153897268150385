import { DateUtils } from "../../helpers/dateUtils";
import { Gender } from "../enums/gender";
import { EditHealthCertificateDto, mapToAddHealthCertificate } from "../healthCertificates/editHealthCertificateDto";
import { DefaultIndicatorsDto } from "../medicalIndicators/defaultIndicatorsDto";
import { EditDriverFormDto } from "./editDriverFormDto";

/**
 *  Create/Edit request Driver data transfer object
 * 
 */
export interface EditDriverDto {
  id: number | null;
  firstName: string;
  lastName: string;
  middleName: string;
  gender: Gender | '';
  birthDate: Date | string | null;
  photoFile?: File | null;
  photoUrl?: string | null;
  employeeNumber: string;
  isDismissed: boolean;
  snils: string | '';
  licenceSerial: string | '';
  licenceNumber: string | '';
  licenceIssued: Date | string | null;
  passportSerial: string | '';
  passportNumber: string | '';
  defaultIndicators: DefaultIndicatorsDto;
  companyId: number;
  departmentIds: number[];
  healthCertificates: EditHealthCertificateDto[];
}

export const mapToEditDriverDto = (driverDto: EditDriverFormDto): EditDriverDto => {
  return {
        id: driverDto.id,
        firstName: driverDto.user.firstName,
        lastName: driverDto.user.lastName,
        middleName: driverDto.user.middleName,
        gender: driverDto.user.gender,
        birthDate: DateUtils.dateToISO(driverDto.user.birthDate),
        photoFile: driverDto.user.photoFile,
        photoUrl: driverDto.user.photoUrl,
        employeeNumber: driverDto.employeeNumber,
        isDismissed: driverDto.isDismissed,
        snils: driverDto.snils,
        licenceSerial: driverDto.licenceSerial,
        licenceNumber: driverDto.licenceNumber,
        licenceIssued: DateUtils.dateToISO(driverDto.licenceIssued),
        passportSerial: driverDto.passportSerial,
        passportNumber: driverDto.passportNumber,
        defaultIndicators: driverDto.defaultIndicators,
        companyId: driverDto.company ? driverDto.company.id : 0,
        departmentIds: driverDto.departmentIds,
        healthCertificates: driverDto.healthCertificates.map(mapToAddHealthCertificate)
  }
};