import React, { useState } from 'react';
import { Box, Dialog, DialogContent, DialogActions, DialogTitle, Backdrop, IconButton } from '@mui/material';
import { driversService } from '../../../services/driversService';
import ErrorAlert from '../../common/ErrorAlert';
import { FormProvider, useForm } from 'react-hook-form';
import { EditMedicalDataTab } from './EditMedicalDataTab';
import { EditPersonalDataTab } from './EditPersonalDataTab';
import { EditDriverFormDto, emptyEditDriverFormDto, mapToEditDriverFormDto } from '../../../models/drivers/editDriverFormDto';
import { DriverDto } from '../../../models/drivers/driverDto';
import Spinner from '../../common/Spinner';
import { mapToEditDriverDto } from '../../../models/drivers/editDriverDto';
import StyledTabs from '../../common/tabs/StyledTabs';
import TmCross from '../../../icons/TmCross';
import TmFullButton from '../../common/buttons/TmFullButton';
import TmEmptyButton from '../../common/buttons/TmEmptyButton';
import StyledTabHidden from '../../common/tabs/StyledTabHidden';

interface EditDriverPopupProps {
  onClose: () => void,
  onRefresh?: () => void,
  isAdding: boolean,
  driver?: DriverDto
}

export const EditDriverModal = ({ onClose, onRefresh, isAdding, driver }: EditDriverPopupProps) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const methods = useForm<EditDriverFormDto>({
    defaultValues: driver ? mapToEditDriverFormDto(driver) : emptyEditDriverFormDto
  });

  const onSubmit = (formData: EditDriverFormDto) => {
    setLoading(true)
    const requestPayload = mapToEditDriverDto(formData);
    if (isAdding) {
      driversService.addDriver(requestPayload)
        .then(r => {
          onClose();
          if (onRefresh) {
            onRefresh();
          }
        })
        .catch(e => {
          console.log(e);
          const error = e.response.data.message ?? e.message;
          showAlert(error);
        })
        .finally(() => setLoading(false));
    } else {
      driversService.updateDriver(requestPayload)
        .then(r => {
          onClose();
          if (onRefresh) {
            onRefresh();
          }
        })
        .catch(e => {
          console.log(e);
          const error = e.response.data.message ?? e.message;
          showAlert(error);
        })
        .finally(() => setLoading(false));
    }
  };

  const onSubmitError = (errors: any) => {
    showAlert("Заполните все обязательные поля");
    console.log('Form Errors:', errors);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const showAlert = (errorMessage: string = 'Произошла ошибка') => {
    setError(errorMessage);
  };

  const dialogContentStyles = {
    width: '500px',
    height: '1000px',
  };

  return (
    <Box>
        <FormProvider {...methods}>
          <Dialog open={!loading} onClose={onClose} PaperProps={{ sx: { borderRadius: "25px" } }}>
            {error && (
              <ErrorAlert
                message={error}
                onClose={() => setError('')}
              />
            )}
            
            <DialogTitle sx={{ textAlign: 'center', pb: "0px", mb: "-40px", zIndex: 9000 }}>{isAdding ? "Добавление" : "Редактирование данных"} водителя</DialogTitle>
            {!error && (<IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                zIndex: 10000
              }}
            >
            <TmCross fill="none" stroke="#cccdd5"/>
            </IconButton>
            )}
            <Box component="form" sx={{ mx: "20px"}}>
              <StyledTabs value={currentTab} onChange={handleTabChange}>
                <StyledTabHidden label='' sx={{ width: '50%' }} />
                <StyledTabHidden label='' sx={{ width: '50%' }} />
              </StyledTabs>
            </Box>
            <DialogContent style={dialogContentStyles}>
              <Box component="form" sx={{ width: '100%' }}>
                 
                <Box>
                  <Box style={{ display: currentTab === 0 ? 'block' : 'none' }}>
                    <EditPersonalDataTab setError={setError} onRefresh={onRefresh} isAdding={isAdding} />
                  </Box>
                  <Box style={{ display: currentTab === 1 ? 'block' : 'none' }}>
                    <EditMedicalDataTab setError={setError} />
                  </Box>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions sx={{mb: 2}}>
              {currentTab === 0 && (
                <Box width="100%" textAlign="center">
                  {!isAdding && <TmEmptyButton onClick={methods.handleSubmit(onSubmit, onSubmitError)} width="45%" sx={{mx: 0.5}}>
                    Сохранить
                  </TmEmptyButton>}
                  <TmFullButton onClick={() => setCurrentTab(1)} width="45%" sx={{mx: 0.5}}>
                    Далее
                  </TmFullButton>
                </Box>
              )}
              {currentTab === 1 && (
                <Box width="100%" textAlign="center">
                  <TmEmptyButton onClick={() => setCurrentTab(0)} width="45%" sx={{mx: 0.5}}>
                    Назад
                  </TmEmptyButton>
                  
                  <TmFullButton onClick={methods.handleSubmit(onSubmit, onSubmitError)} width="45%" sx={{mx: 0.5}}>
                    Сохранить
                  </TmFullButton>
                </Box>
              )}
              
              
            </DialogActions>
          </Dialog>
        </FormProvider>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Spinner />
      </Backdrop>
    </Box>
  );
};
