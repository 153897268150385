import { ReactNode, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Autocomplete, Box, Button, MenuItem, TextField } from '@mui/material';
import { authService } from '../../services/authService';
import ErrorAlert from '../common/ErrorAlert';
import SuccessModal from '../common/SuccessModal';
import { LookupDto } from '../../models/common/lookup.dto';
import { rolesService } from '../../services/rolesService';
import { useNavigate } from 'react-router';
import { CompanyShortDto } from '../../models/companies/companyShortDto';
import Spinner from '../common/Spinner';
import { RegisterDto, emptyRegisterDto } from '../../models/auth/registerDto';
import { companiesService } from '../../services/companiesService';
import TmFullLoadingButton from '../common/buttons/TmFullLoadingButton';


const Register = () => {
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, watch, control, formState: { errors } } = useForm<RegisterDto>({
        mode: 'onChange',
        defaultValues: emptyRegisterDto
    });
    const [showDialog, setShowDialog] = useState(false);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState('');
    const [roles, setRoles] = useState<LookupDto[]>([]);
    const [companies, setCompanies] = useState<CompanyShortDto[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        rolesService.get()
            .then(r => setRoles(r.data.data))
            .catch(e => console.log(e));

        companiesService.search('')
            .then(r => setCompanies(r.data.data))
            .catch(e => console.log(e));
    }, []);

    const onSubmit = (data: RegisterDto) => {
        setLoading(true);
        const formData = {
            ...data,
            companyId: data.company?.id
        };
        authService.register(formData)
            .then(r => {
                if (r.data.success) {
                    setShowDialog(true);
                }
                else {
                    showAlert(r.data.message);
                }
            })
            .catch(e => showAlert(e.response.data.message))
            .finally(() => setLoading(false));
    };

    const showAlert = (errorMessage: string = 'Произошла ошибка') => {
        setError(errorMessage);
        setShowError(true);
        setTimeout(() => setShowError(false), 10000);
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
        navigate('/login');
    };

    const email = watch('email');

    return (
        <div>
            {loading
                ? <Spinner />
                : (
                    <div><Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
                        <TextField
                            required
                            fullWidth
                            label="Имя"
                            margin="normal"
                            {...register('firstName', {
                                required: 'Поле обязательно для заполнения',
                            })}
                            error={Boolean(errors.firstName)}
                            helperText={errors.firstName?.message as ReactNode} />
                        <TextField
                            required
                            fullWidth
                            label="Фамилия"
                            margin="normal"
                            {...register('lastName', {
                                required: 'Поле обязательно для заполнения',
                            })}
                            error={Boolean(errors.lastName)}
                            helperText={errors.lastName?.message as ReactNode}
                        />
                        <TextField
                            fullWidth
                            label="Отчество"
                            margin="normal"
                            {...register('middleName')}
                            error={Boolean(errors.middleName)}
                            helperText={errors.middleName?.message as ReactNode}
                        />
                        <TextField
                            required
                            fullWidth
                            label="Email"
                            margin="normal"
                            {...register('email', {
                                required: 'Поле обязательно для заполнения',
                                pattern: {
                                    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                    message: 'Невалидный email',
                                },
                            })}
                            error={Boolean(errors.email)}
                            helperText={errors.email?.message as ReactNode} />
                        <TextField
                            fullWidth
                            label="Телефон"
                            margin="normal"
                            {...register('phoneNumber')}
                            error={Boolean(errors.phoneNumber)}
                            helperText={errors.phoneNumber?.message as ReactNode} />

                        <Controller
                            name='company'
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                    onChange={(event, item) => {
                                        onChange(item);
                                    }}
                                    value={value}
                                    options={companies}
                                    getOptionLabel={(item) => (item.name ? item.name : "")}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    noOptionsText="Не найдено"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={'Клиент'}
                                            margin="normal"
                                            variant="outlined"
                                            error={Boolean(errors.company)}
                                            helperText={errors.company && "Поле обязательно для заполнения"}
                                            required
                                        />
                                    )}
                                />
                            )}
                        />

                        <TextField
                            required
                            select
                            fullWidth
                            label="Роль"
                            margin="normal"
                            defaultValue=''
                            inputProps={register('roleId', {
                                required: 'Поле обязательно для заполнения',
                            })}
                            error={Boolean(errors.roleId)}
                            helperText={errors.roleId?.message}
                        >
                            {roles.map((role) => (
                                <MenuItem key={role.id} value={role.id}>
                                    {role.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TmFullLoadingButton
                            type="submit"
                            sx={{ mt: 3, mb: 2 }}
                            loading={loading}
                            loadingPosition="start"
                            width="100%"
                        >
                            Зарегистрироваться
                        </TmFullLoadingButton>

                        {showError && (
                            <ErrorAlert
                                message={error}
                                onClose={() => setShowError(false)}
                                autoCloseDuration={10000} />
                        )}
                    </Box><SuccessModal
                            open={showDialog}
                            text={`Ссылка на установку пароля отправлена на почту ${email}`}
                            onClose={handleCloseDialog} /></div>
                )}
        </div>
    );
};

export default Register;
