import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Box, Typography, TextField, IconButton, InputAdornment, Grid, TableSortLabel, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { terminalsService } from '../../services/terminalsService';
import { CategoryType } from '../../models/enums/categoryType';
import { PermissionType } from '../../models/enums/permissionType';
import { ShowForPermission } from '../common/ShowForPermission';
import TmPlus from '../../icons/TmPlus';
import { TerminalShortInfoDto } from '../../models/terminals/terminalShortInfoDto';
import { ROUTES } from '../../constants/routes';
import { EditTerminalModal } from './edit/EditTerminalModal';
import AcceptLabel from '../common/labels/AcceptLabel';
import DenyLabel from '../common/labels/DenyLabel';
import { SortingOrder } from '../../models/enums/sortingOrder';
import { CompanyShortDto } from '../../models/companies/companyShortDto';
import CompanyAutocomplete from '../companies/CompanyAutocomplete';

const PAGE_REFRESH_INTERVAL = 10000;

export const TerminalsTable = () => {
    const [state, setState] = useState({
        terminals: [] as TerminalShortInfoDto[],
        page: 0,
        rowsPerPage: 15,
        dataCount: 0,
        loading: true,
        isAddPopupOpen: false,
        filter: {
            name: '' as string,
            number: '' as string | null,
            company: null as CompanyShortDto | null,
        },
        sortConfig: {
            sortColumn: 'id',
            sortOrder: SortingOrder.Asc,
            loadingColumn: null as string | null,
        },
        shouldFetch: true,
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (state.shouldFetch) {
            fetchTerminals();
            setState(prevState => ({
                ...prevState,
                shouldFetch: false,
            }));
        }
        const interval = setInterval(fetchTerminals, PAGE_REFRESH_INTERVAL);
        return () => clearInterval(interval); // Clear interval on component unmount
    }, [state.page, state.rowsPerPage, state.shouldFetch]);

    const fetchTerminals = () => {
        setState(prevState => ({ ...prevState, loading: true }));
        terminalsService.getByFilter({
            page: state.page,
            pageSize: state.rowsPerPage,
            name: state.filter.name || undefined,
            number: state.filter.number || undefined,
            companyId: state.filter.company ? state.filter.company.id : undefined,
            sortColumn: state.sortConfig.sortColumn,
            sortOrder: state.sortConfig.sortOrder,
        })
            .then(r => {
                setState(prevState => ({
                    ...prevState,
                    terminals: r.data.data.items,
                    dataCount: r.data.data.count,
                }));
            })
            .catch(e => console.log(e))
            .finally(() => {
                setState(prevState => ({
                    ...prevState,
                    loading: false,
                    sortConfig: { ...prevState.sortConfig, loadingColumn: null },
                }));
            });
    };

    const handleChangePage = (event: any, newPage: number) => {
        setState(prevState => ({
            ...prevState,
            page: newPage,
            shouldFetch: true,
        }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setState(prevState => ({
            ...prevState,
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
            shouldFetch: true,
        }));
    };

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                [event.target.name]: event.target.value,
            },
            page: 0,
            shouldFetch: true,
        }));
    };

    const handleCompanyChange = (newCompany: CompanyShortDto | null) => {
        setState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                company: newCompany,
            },
            shouldFetch: true,
        }));
    };

    const handleAddPopupToggle = () => {
        setState(prevState => ({
            ...prevState,
            isAddPopupOpen: !prevState.isAddPopupOpen,
        }));
    };

    const handleRowClick = (event: React.MouseEvent, terminalId: number) => {
        const url = ROUTES.terminals.idPath.replace(':id', String(terminalId));
        navigate(url);
    };

    const handleSort = (column: string) => {
        const isAsc = state.sortConfig.sortColumn === column && state.sortConfig.sortOrder === SortingOrder.Asc;
        setState(prevState => ({
            ...prevState,
            sortConfig: {
                sortColumn: column,
                sortOrder: isAsc ? SortingOrder.Desc : SortingOrder.Asc,
                loadingColumn: column,
            },
            shouldFetch: true,
        }));
    };

    return (
        <Box>
            <Grid container pb={1} mt={2.5}>
                <Grid item xs={10} style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        label="Серийный номер"
                        name="number"
                        value={state.filter.number || ''}
                        onChange={handleFilterChange}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        style={{ width: 200, marginRight: 10 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setState(prevState => ({ ...prevState, page: 0, shouldFetch: true }))}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            style: {
                                borderRadius: "10px",
                            }
                        }}
                    />

                    <TextField
                        label="Название"
                        name="name"
                        value={state.filter.name}
                        onChange={handleFilterChange}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        style={{ width: 200, marginRight: 10 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setState(prevState => ({ ...prevState, page: 0, shouldFetch: true }))}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            style: {
                                borderRadius: "10px",
                            }
                        }}
                    />

                    <CompanyAutocomplete
                        company={state.filter.company ?? null}
                        onCompanyChange={handleCompanyChange}
                        style={{ width: 200, marginRight: 10 }}
                    />
                </Grid>
                <Grid item xs={2} style={{ display: 'inline-block', alignItems: 'center' }}>
                    <ShowForPermission catalog={CategoryType.Terminals} permission={PermissionType.Add}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton sx={{ display: "flex" }} className="list-item" onClick={handleAddPopupToggle} >
                                <TmPlus fill="none" stroke="white" />&nbsp;
                                <Typography fontWeight="bold" pr={1}>
                                    &nbsp;Добавить новый терминал
                                </Typography>
                            </IconButton>
                        </Box>
                    </ShowForPermission>
                </Grid>
            </Grid>

            <TableContainer sx={{ '.MuiTableCell-root': { py: 1.5 } }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: '10%' }}>
                                <TableSortLabel
                                    active={state.sortConfig.sortColumn === 'id'}
                                    direction={state.sortConfig.sortColumn === 'id' ? state.sortConfig.sortOrder : SortingOrder.Asc}
                                    onClick={() => handleSort('id')}
                                    IconComponent={state.sortConfig.loadingColumn === 'id' ? () => <CircularProgress size={12} sx={{ color: '#9588e8' }} /> : undefined}
                                >
                                    <Typography variant="body1" sx={{ color: "#7c7e92" }}>ID</Typography>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ width: '15%' }}>
                                <TableSortLabel
                                    active={state.sortConfig.sortColumn === 'number'}
                                    direction={state.sortConfig.sortColumn === 'number' ? state.sortConfig.sortOrder : SortingOrder.Asc}
                                    onClick={() => handleSort('number')}
                                    IconComponent={state.sortConfig.loadingColumn === 'number' ? () => <CircularProgress size={12} sx={{ color: '#9588e8' }} /> : undefined}
                                >
                                    <Typography variant="body1" sx={{ color: "#7c7e92" }}>Серийный номер</Typography>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ width: '20%' }}>
                                <TableSortLabel
                                    active={state.sortConfig.sortColumn === 'name'}
                                    direction={state.sortConfig.sortColumn === 'name' ? state.sortConfig.sortOrder : SortingOrder.Asc}
                                    onClick={() => handleSort('name')}
                                    IconComponent={state.sortConfig.loadingColumn === 'name' ? () => <CircularProgress size={12} sx={{ color: '#9588e8' }} /> : undefined}
                                >
                                    <Typography variant="body1" sx={{ color: "#7c7e92" }}>Название</Typography>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ width: '20%' }}>
                                <TableSortLabel
                                    active={state.sortConfig.sortColumn === 'companies'}
                                    direction={state.sortConfig.sortColumn === 'companies' ? state.sortConfig.sortOrder : SortingOrder.Asc}
                                    onClick={() => handleSort('companies')}
                                    IconComponent={state.sortConfig.loadingColumn === 'companies' ? () => <CircularProgress size={12} sx={{ color: '#9588e8' }} /> : undefined}
                                >
                                    <Typography variant="body1" sx={{ color: "#7c7e92" }}>Клиенты</Typography>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ width: '20%' }}><Typography variant="body1" sx={{ color: "#7c7e92" }}>Адрес</Typography></TableCell>
                            <TableCell sx={{ width: '15%' }}>
                                <TableSortLabel
                                    active={state.sortConfig.sortColumn === 'isActive'}
                                    direction={state.sortConfig.sortColumn === 'isActive' ? state.sortConfig.sortOrder : SortingOrder.Asc}
                                    onClick={() => handleSort('isActive')}
                                    IconComponent={state.sortConfig.loadingColumn === 'isActive' ? () => <CircularProgress size={12} sx={{ color: '#9588e8' }} /> : undefined}
                                >
                                    <Typography variant="body1" sx={{ color: "#7c7e92" }}>Статус</Typography>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.terminals.map((terminal) => (
                            <TableRow
                                key={terminal.id}
                                onClick={(event) => handleRowClick(event, terminal.id)}
                                sx={{ '&:hover': { cursor: 'pointer', backgroundColor: 'rgba(0, 0, 0, 0.08)' } }}
                            >
                                <TableCell sx={{ fontWeight: "bold" }}>{terminal.id}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{terminal.number}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{terminal.name}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{terminal.companies.length > 0 ? terminal.companies.map(company => company.name).join(', ') : '<Отсутствует>'}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{terminal.address}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{terminal.isActive ? <AcceptLabel>Активен</AcceptLabel> : <DenyLabel>Не активен</DenyLabel>}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography>Всего: {state.dataCount}</Typography>
                <TablePagination
                    rowsPerPageOptions={[15, 30, 50]}
                    component="div"
                    count={state.dataCount}
                    rowsPerPage={state.rowsPerPage}
                    page={state.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={"Строк на странице:"}
                    labelDisplayedRows={({ from, to, count }: any) => `Показано ${from}-${to} из ${count}`}
                />
            </Box>
            <EditTerminalModal onClose={handleAddPopupToggle} onRefresh={fetchTerminals} isAdding={true} open={state.isAddPopupOpen} />
        </Box>
    );
};